<template>
  <MainLayout :title="title" v-on:logout="$emit('logout')">
    <v-list>
      <v-list-item
        v-for="(document, key) in $mystore.state.documents"
        :key="key"
        @click.stop="download(document.link)"
      >
        <v-list-item-avatar>
          <v-icon
            :class="[document.getIconClass()]"
            v-text="document.getIcon()"
          ></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <!-- <a target="_blank" :href="document.link">{{
              document.original_name
            }}</a> -->
            {{ document.original_name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/Main.vue'
import handle_errors from '@/api/handle_errors.js'
import Document from '@/models/Document.js'
import catch_errors from '@/api/catch_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import getDocument from '@/api/getDocument.js'

async function get_documents(token) {
  return await fetch(`${process.env.VUE_APP_API_URL}/voter/documents`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

function map_documents(documents) {
  return documents.map(document => {
    return new Document(document)
  })
}

export default {
  name: 'Documents',

  components: {
    MainLayout,
  },

  data() {
    return {
      title: this.$t('views.documents.title'),
      pusherChannel_documents: null,
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.documents.title')
      document.title = this.title
    },

    '$mystore.state.pusherInstance': function (value) {
      if (value === null) return

      this.bindToPusherEvents()
    },
  },

  created: function () {
    document.title = this.title

    this.bindToPusherEvents()

    get_documents(localStorage.getItem('token'))
      .then(handle_token_refresh)
      .then(handle_errors)
      .then(data => {
        this.$mystore.setDocuments(map_documents(data.data.documents))
      })
      .catch(response => {
        catch_errors(response, this.$router, this.$mystore)
      })
  },

  destroyed: function () {
    if (this.pusherChannel_documents === null) return

    this.$mystore.state.pusherInstance.unsubscribe(
      `private-encrypted-event_${this.$mystore.state.event.id}_documents`
    )

    this.pusherChannel_documents = null
  },

  methods: {
    download(url) {
      window.open(url, '_blank')
    },

    bindToPusherEvents() {
      if (this.$mystore.state.pusherInstance === null) return

      this.pusherChannel_documents = this.$mystore.state.pusherInstance.subscribe(
        `private-encrypted-event_${this.$mystore.state.event.id}_documents`
      )

      this.pusherChannel_documents.bind('set-to-private', document_id => {
        this.$mystore.removeDocument(document_id)
      })

      this.pusherChannel_documents.bind('set-to-public', document_id => {
        getDocument(
          document_id,
          data => this.$mystore.addDocument(data.data),
          response => catch_errors(response, this.$router, this.$mystore)
        )
      })

      this.pusherChannel_documents.bind('new', document_id => {
        getDocument(
          document_id,
          data => this.$mystore.addDocument(data.data),
          response => catch_errors(response, this.$router, this.$mystore)
        )
      })

      this.pusherChannel_documents.bind('delete', document_id => {
        this.$mystore.removeDocument(document_id)
      })

      this.pusherChannel_documents.bind('update', document_id => {
        getDocument(
          document_id,
          data => this.$mystore.updateDocument(data.data),
          response => catch_errors(response, this.$router, this.$mystore)
        )
      })
    },
  },
}
</script>
