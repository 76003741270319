export default async function (message_id, token) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/documents/${message_id}`,
    {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
