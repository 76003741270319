import handle_errors from '@/api/handle_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import get_document from '@/api/get_document.js'

export default function (id, success, failure) {
  get_document(id, localStorage.getItem('token'))
    .then(handle_token_refresh)
    .then(handle_errors)
    .then(success)
    .catch(failure)
}
